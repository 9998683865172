 // Video Popup
    jQuery(document).ready(function( $ ) {
        $('.v-card-image a#video').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            disableOn: 320,
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
            enabled: false
        });
    });