const header = document.getElementsByClassName('site-header')[0];
const entryContent = document.getElementsByClassName('entry-content')[0];
const contentArea = document.getElementsByClassName('content-area')[0];
const postArea = document.getElementById('main');



if (entryContent) {
  const isHeroFirst = entryContent.children[0].classList.contains('hero');

  if (! isHeroFirst) {
    header.classList.add('site-header--inner');
    contentArea.classList.add('u-bg-contour');
  }
} else if (postArea) {
  const isHeroFirst = postArea.children[0].classList.contains('hero');

  if (! isHeroFirst) {
    header.classList.add('site-header--inner');
    contentArea.classList.add('u-bg-contour');
  }
}