const partners = document.getElementsByClassName('partners-list');
const featuredPosts = document.getElementsByClassName('featured-posts__wrapper');

console.log(partners, featuredPosts);

if (partners.length) {
  var partnerSlider = tns({
    container: '.partners-list',
    items: 2,
    slideBy: 1,
    controls: true,
    autoplay: false,
    autoplayButton: false,
    nav: false,
    gutter: 30,
    edgePadding: 20,
    controlsContainer: '.partners-list__controls',
    responsive: {
      500: {
        gutter: 70,
        items: 4
      }
    },
  });
};


if (featuredPosts.length) {
  var featuredPostSlider = tns({
    container: '.featured-posts__wrapper',
    items: 1,
    slideBy: 1,
    controls: true,
    autoplay: false,
    nav: true,
    autoplayButton: false,
    controlsContainer: '.featured-dots'
  })
};