const sectionIntro = document.getElementsByClassName('section--intro')[0];
const sectionContact = document.getElementsByClassName('section--contact-details')[0];
const sectionEntry = document.getElementsByClassName('entry-content')[0];

if (sectionIntro && sectionContact) {
  const children = Array.prototype.slice.call(sectionEntry.children);
  
  children.map(function (child, index) {
    if (child.classList.contains('intro') && children[index + 1].classList.contains('section--contact-details')) {
      child.classList.add('section--contact-intro');
    }
  })
}