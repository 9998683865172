const menu = {
  wrapper: document.getElementById('primary-menu'),
  button: document.getElementById('js-menu-button'),
  body: document.getElementsByClassName('archive')[0],

  handleClick: function () {
    this.wrapper.classList.toggle('nav--active');
    this.button.classList.toggle('nav__button--active');
    this.body.classList.toggle('body--fixed');
  }
}

menu.button.addEventListener('click', function() {
  menu.handleClick(menu);
});

menu.button.addEventListener('touchStart', function() {
  menu.handleClick(menu);
});