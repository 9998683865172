/* 
Accordion (FAQs)
*/

(function ($) {

        $(document).on('click', 'a[href^="#"]', function (event) {
          event.preventDefault();

          $('html, body').animate({
              scrollTop: $($.attr(this, 'href')).offset().top
          }, 500);
      });

        $('.accordion-answer').hide();   
        $('.accordion-question').click(function(){
            if($(this).next().is(':hidden')) {
                $('.accordion-question').removeClass('active').next().slideUp('slow');
                $(this).toggleClass('active').next().slideDown('slow');
            } else if($(this).next().is(':visible')) {
                $(this).toggleClass('active').next().slideUp('slow');
            }
        });

})(jQuery);
